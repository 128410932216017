import { ErrorMessage } from "formik";
import styles from "./inputErrorMessage.module.scss";

export const InputErrorMessage = ({ name, ...rest }) => {
  return (
    <span className={styles.errorMessageText} {...rest}>
      <ErrorMessage name={name} />
    </span>
  );
};
