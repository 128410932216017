import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Success from "../../success";
import { addToken } from "../../../../store/reducers/userToken";

const TestAuthentications = () => {
  const { userId } = useParams();
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("array-event", handleArrayEvent);

    return () => {
      window.removeEventListener("array-event", handleArrayEvent);
    };
  }, [userId]);

  const handleArrayEvent = (arrayEvent) => {
    const { tagName, event, metadata = {} } = arrayEvent.detail;
    const payload = {
      userId: userId,
      userToken: metadata?.["user-token"],
    };
    if (event === "success" && Object.keys(metadata).length > 0) {
      setSuccess(true);
      dispatch(
        addToken({
          payload,
          isTestEnv: true,
        })
      );
    }
  };
  return (
    <div>
      {!success && (
        <array-authentication-kba
          appKey={process.env.REACT_APP_ARRAY_APP_KEY}
          userId={userId}
          sandbox="true"
          showResultPages="true"
        ></array-authentication-kba>
      )}
      {success && <Success />}
    </div>
  );
};
export default TestAuthentications;
