import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchDeleteRequest,
  fetchGetRequest,
  fetchPostRequest,
} from "../../utils/network";
import endpoint from "../../utils/endpoint";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  data: {},
  errors: null,
};

export const getUserToken = createAsyncThunk(
  "userToken/getUser",
  async ({ userId, isTestEnv }, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        endpoint.user.get(userId),
        {},
        isTestEnv
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
      const message = err?.data.message || "Something went wrong";
      return thunkAPI.rejectWithValue(err.data);
    }
  }
);

export const addToken = createAsyncThunk(
  "userToken/addToken",
  async ({ payload, isTestEnv = false }, thunkAPI) => {
    try {
      const response = await fetchPostRequest(
        endpoint.user.addToken,
        {
          userId: payload?.userId,
          userToken: payload?.userToken,
        },
        null,
        isTestEnv
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      const validationErrors = error.data.errors.reduce((acc, message) => {
        return {
          ...acc,
          [message.fieldName]: message.fieldError.join(", ").trim(),
        };
      }, {});
      return thunkAPI.rejectWithValue(validationErrors);
    }
  }
);

const { reducer, actions } = createSlice({
  name: "userToken",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserToken.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserToken.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.data = action.payload;
    });
    builder.addCase(getUserToken.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.data = [];
    });
    builder.addCase(addToken.rejected, (state, action) => {
      state.errors = action.payload;
    });
    builder.addCase(addToken.fulfilled, (state, action) => {
      state.errors = null;
    });
  },
});
export default reducer;
