import constants from "./constants";
import toast from "react-hot-toast";

const toastMessage = (type, messsage) => {
  switch (type) {
    case constants.TOAST_TYPE.SUCCESS:
      return toast.success(messsage);
    case constants.TOAST_TYPE.ERROR:
      return toast.error(messsage);

    default:
      break;
  }
};

export default toastMessage;
