export default {
  API_BASE_ENDPOINT: process.env.REACT_APP_API_BASE_ENDPOINT,
  API_Test_BASE_ENDPOINT: process.env.REACT_APP_API_TEST_BASE_ENDPOINT,
  AI_TEST_ENDPONIT: process.env.REACT_APP_AI_CHAT_ENDPONT,
  THUNK_STATUS: {
    FULFILLED: "fulfilled",
    REJECTED: "rejected",
  },
  TOAST_TYPE: {
    SUCCESS: "success",
    ERROR: "error",
  },
};
