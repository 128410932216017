import "./App.css";
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import AppRoutes from "./AppRoutes";
import { store } from "./store";
import { Provider } from "react-redux";

function App() {
  return (
    <Provider store={store}>
      <Suspense fallback={<p>Loading</p>}>
        <ErrorBoundary FallbackComponent={() => <p>Something went wrong</p>}>
          <HelmetProvider>
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </HelmetProvider>
        </ErrorBoundary>
      </Suspense>
    </Provider>
  );
}

export default App;
