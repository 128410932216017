// import React from 'react';
import React from "react";
import "owl.carousel/dist/assets/owl.theme.default.css";
import styles from "./success.module.scss";

const testimonialScrl = {
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1261: {
      items: 3,
    },
  },
};

const Success = () => {
  return (
    <>
      {/* ================ Banner Section Start ================ */}
      <section className={styles.successMainSec}>
        <div className={styles.successMainPopup}>
          <img
            src={"../images/success-icon.png"}
            alt="success"
            title="success"
          />
          <h2>Verified</h2>
          <p>Please go back and continue using the application</p>
          {/* <a className="dfltBtn" href="#">Ok</a> */}
        </div>
      </section>
      {/* ================ Banner Section End ================ */}
    </>
  );
};

export default Success;
