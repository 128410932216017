import { useDispatch } from "react-redux";
import styles from "./creditGenius.module.scss";
import { useEffect, useRef, useState } from "react";
import { askQuestion, getHistory } from "../../../store/reducers/chat";
import constants from "../../../utils/constants";
import { Formik } from "formik";

const CreditGenius = ({
  userId,
  isEmailVerified,
  isSubscribed,
  setActiveTab,
}) => {
  const dispatch = useDispatch();
  const [chatHistory, setChatHistory] = useState([]);
  const chatEndRef = useRef(null); // Reference to the bottom of the chat container
  const [isVerifiedButton, setIsVerifiedButton] = useState(false); // For "Verify Now" button
  const [isLoading, setIsLoading] = useState(false); // For loading indicator

  useEffect(() => {
    const payload = { userId: userId, size: 100 };
    dispatch(getHistory({ payload, isTestEnv: true })).then((response) => {
      if (response?.meta?.requestStatus === constants.THUNK_STATUS.FULFILLED) {
        setChatHistory(response?.payload?.chat_history);
      }
    });
  }, [dispatch, userId]);

  // Scroll to the bottom whenever chatHistory changes
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatHistory]);

  const onSubmit = (values, { resetForm }) => {
    setIsVerifiedButton(false);
    if (!values?.question) return;

    const userMessage = {
      text: values?.question || "",
      sent_by_user: true,
      time: new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      id: Math.floor(Math.random() * 100),
    };

    setChatHistory([...chatHistory, userMessage]);
    setIsLoading(true); // Start loading

    const payload = {
      user_input: values?.question || "",
      userId,
      isPremiumUser: isSubscribed || false,
      isVerifiedUser: isEmailVerified,
    };

    dispatch(askQuestion({ payload, isTestEnv: true })).then((response) => {
      if (response?.meta?.requestStatus === constants.THUNK_STATUS.FULFILLED) {
        const geniusMessage = {
          text: response?.payload?.response,
          sent_by_user: false,
          time: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          id: Math.floor(Math.random() * 100),
        };

        setChatHistory((prevHistory) => [...prevHistory, geniusMessage]);
        setIsVerifiedButton(response?.payload?.verified_button);
      }
      setIsLoading(false); // End loading
    });

    resetForm(); // Reset the question input field after submission
  };

  const handleVerification = () => {
    setActiveTab("Dashboard");
  };

  return (
    <div className={styles.chatBoxWrap}>
      <div className={styles.chatBoxHeader}>
        <div className={styles.chatBoxHeaderLeft}>
          <img
            src="../../images/logo.png"
            alt="Credit Genius"
            title="Credit Genius"
          />
          <h4>Credit Genius</h4>
        </div>
        <div className={styles.chatBoxHeaderRight}>
          Question:{" "}
          <b>
            {chatHistory.length > 0 ? (
              <>
                <span>
                  {
                    chatHistory.filter((chat) => chat.sent_by_user === true)
                      .length
                  }
                </span>
                &nbsp; / 10
              </>
            ) : (
              <span>0 / 10</span>
            )}
          </b>
        </div>
      </div>
      <div className={styles.chatInfoWrapper}>
        {chatHistory.length === 0 ? (
          <div className={styles.noChatMessage}>
            No Interaction With Credit Genius
          </div>
        ) : (
          chatHistory.map((chat) => (
            <div
              key={chat.id}
              className={`${styles.chatQuestionWrapper} ${chat.sent_by_user ? styles.chatRight : ""
                }`}
            >
              <div className={styles.chatQuestionBox}>
                {!chat.sent_by_user && (
                  <div className={styles.chatQuestionImg}>
                    <img
                      src="../../images/logo.png"
                      alt="Credit Genius"
                      title="Credit Genius"
                    />
                  </div>
                )}
                <div className={styles.chatQuestionCol}>{chat.text}</div>
                <span className={styles.chatTime}>{chat.time}</span>
              </div>
            </div>
          ))
        )}
        {/* Loader for showing "..." while waiting for a response */}
        {isLoading && (
          <div className={styles.chatQuestionWrapper}>
            <div className={styles.chatQuestionBox}>
              <div className={styles.chatQuestionImg}>
                <img
                  src="../../images/logo.png"
                  alt="Credit Genius"
                  title="Credit Genius"
                />
              </div>
              <div className={styles.chatQuestionCol}>
                <div className={styles.loaderDots}></div>
              </div>
            </div>
          </div>
        )}
        <div ref={chatEndRef} /> {/* This element will be scrolled into view */}
      </div>

      <Formik initialValues={{ question: "" }} onSubmit={onSubmit}>
        {({ values, handleChange, handleSubmit }) => (
          <div className={styles.chatBoxBottm}>
            {isVerifiedButton && ( // Conditionally render the floating bar with "Verify Now" button
              <div className={styles.floatingVerifyBar}>
                <span>Please Complete Your Profile and Verify Yourself</span>
                <button
                  className={styles.verifyButton}
                  onClick={() => handleVerification()}
                >
                  Verify Now
                </button>
              </div>
            )}
            <div className={styles.chatForm}>
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder="Ask any question"
                  name="question"
                  onChange={handleChange}
                  value={values.question}
                />
                <div className={styles.chatSendAction}>
                  <button type="submit">
                    <img
                      src="../../images/send-icon.png"
                      title="send chat"
                      alt="send"
                    />
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default CreditGenius;
