import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from "./reducers/auth";
import profileReducer from "./reducers/user";
import stateReducer from "./reducers/states";
import userToekenReducer from "./reducers/userToken";
import chatReducer from "./reducers/chat";
import { initializeInterceptors } from "../utils/network";
import { thunk } from "redux-thunk";

const reducers = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  states: stateReducer,
  userToken: userToekenReducer,
  chatReducer: chatReducer
}); // here goes all reducers

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"], // here goes those items which you want to store in localstorage
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

initializeInterceptors(store);

export const persistor = persistStore(store);
