export default {
  user: {
    get: (userId) => `/auth/userToken/${userId}`,
    addToken: "/auth/add-userToken",
    login: "/sso/sso-login",
    getUserDetails: "/auth/me",
    logout: "/auth/logout",
    renewToken: "auth/renew-token",
  },
  profile: {
    get: "/profile",
    update: "user/complete-profile",
  },
  state: {
    get: "/states/list",
  },
  chat: {
    get: "/chat/getChatHistory",
    post: 'chat/askCreditGenius'
  },
};
