// import React from 'react';
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../shared/header/header";
import Footer from "../../shared/footer/footer";
import "owl.carousel/dist/assets/owl.theme.default.css";
import styles from "./privacy.module.scss";

const testimonialScrl = {
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1261: {
      items: 3,
    },
  },
};

const PrivacyPolicy = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const header = document.querySelector(".siteTopNavbar");
    const scrollTop = window.scrollY;
    if (header) {
      const navPosition = header.offsetTop;
      const navTop = window.scrollY || document.documentElement.scrollTop;
      if (navPosition < navTop) {
        header?.classList?.add("stickyHeader");
      } else {
        header?.classList?.remove("stickyHeader");
      }
    }
  };
  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
    document.body.classList.toggle("body-fixed");
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - (window.innerWidth < 992 ? 150 : 120),
        behavior: "smooth",
      });
    }
  };

  const handleMenuClick = (sectionId) => {
    toggleMenu();
    scrollToSection(sectionId);
  };

  const handleMenuLinkClick = (sectionId) => {
    setIsMenuOpen(false);
    scrollToSection(sectionId);
  };

  return (
    <>
      {/* <Header/> */}

      <div
        className={`${styles.topMainHdr} siteTopNavbar ${
          isMenuOpen ? "show-nav" : ""
        }`}
      >
        <div className="container">
          <div className={styles.hdrRow}>
            <div className={styles.hdrLogo}>
              <a href={"/"}>
                <img
                  src={"../../images/logo.png"}
                  alt="Your Company Logo"
                  title="Comapny Logo"
                />
              </a>
            </div>
            <div className={styles.hdrMenuPrt}>
              <button
                className={`navbar-toggler ${isMenuOpen ? "open" : ""}`}
                type="button"
                onClick={toggleMenu}
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </button>
              <div className={styles.headerMenu}>
                <nav className="navbar navbar-light navbar-expand-lg">
                  <div
                    className={`collapse navbar-collapse ${
                      isMenuOpen ? "show" : ""
                    }`}
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav">
                      <li>
                        <Link
                          to="/#about-sec"
                          onClick={() => handleMenuClick("about-sec")}
                        >
                          {" "}
                          {/* Replaced href with to */}
                          About Credit Secret
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/#personalCredit"
                          onClick={() => {
                            handleMenuClick("personalCredit");
                          }}
                        >
                          Personal Credit
                        </Link>
                      </li>
                      <li>
                        <a
                          href="/#creditGenius"
                          onClick={() => {
                            handleMenuClick("creditGenius");
                          }}
                        >
                          Credit Genius
                        </a>
                      </li>
                      <li>
                        <a
                          href="/#creditGames"
                          onClick={() => {
                            handleMenuClick("creditGames");
                          }}
                        >
                          Credit Games
                        </a>
                      </li>
                      <li>
                        <a
                          href="/#creditSimulator"
                          onClick={() => {
                            handleMenuClick("creditSimulator");
                          }}
                        >
                          Credit Simulator
                        </a>
                      </li>

                      <li>
                        <a
                          href="/#downloadApp"
                          onClick={() => {
                            handleMenuClick("downloadApp");
                          }}
                        >
                          Download App
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================ Banner Section Start ================ */}
      <section className={styles.faqBannerSec}>
        <div className="container">
          <div className={styles.bannerRow}>
            <h3 className="mt-5">
              Privacy Policy for The Great American Credit Secret LLC
            </h3>
            <p>
              Welcome to The Great American Credit Secret LLC, a financial
              literacy app designed to empower you with tools and knowledge to
              improve your financial health, including features that allow you
              to check your credit information. We are committed to protecting
              your privacy and handling your personal information with care and
              respect.
            </p>

            <p>
              This Privacy Policy explains how we collect, use, share, and
              protect information in relation to our mobile app, website, and
              any software provided on or in connection with The Great American
              Credit Secret LLC services (collectively, the “Service”), and your
              choices about the collection and use of your information.
            </p>

            <h4>1. Information We Collect</h4>
            <p>We collect the following types of information:</p>
            <p>
              <strong>a. Information You Provide Us Directly:</strong>
            </p>
            <ul>
              <li>
                Your name, email address, phone number, and other contact
                information when you register for our Service.
              </li>
              <li>
                Financial information including your credit history, scores, and
                financial transactions when you use features designed to check
                your credit.
              </li>
              <li>
                Communications between you and The Great American Credit Secret
                LLC. For example, we may send you Service-related emails (e.g.,
                account verification, changes/updates to features, technical and
                security notices).
              </li>
            </ul>
            <p>
              <strong>
                b. Information We May Collect from Your Use of the Service:
              </strong>
            </p>
            <ul>
              <li>Log file information.</li>
              <li>Device identifiers.</li>
              <li>Location information (if you grant permission).</li>
              <li>
                Information collected by cookies and other tracking
                technologies.
              </li>
            </ul>

            <h4>2. How We Use Your Information</h4>
            <p>We use the information we collect to:</p>
            <ul>
              <li>Operate, maintain, and improve our Service.</li>
              <li>
                Understand you and your preferences to enhance your experience
                and enjoyment using our Service.
              </li>
              <li>
                Respond to your comments and questions and provide customer
                service.
              </li>
              <li>Provide and deliver products and services you request.</li>
              <li>
                Send you related information, including confirmations, invoices,
                technical notices, updates, security alerts, and support and
                administrative messages.
              </li>
              <li>
                Communicate with you about promotions, upcoming events, and
                other news about products and services offered by The Great
                American Credit Secret LLC and our selected partners.
              </li>
            </ul>

            <h4>3. Sharing of Your Information</h4>
            <p>
              We will not rent or sell your information to third parties outside
              The Great American Credit Secret LLC without your consent, except
              as noted below:
            </p>

            <ul>
              <li>
                We may share your information with third-party business
                partners, vendors, and consultants who perform services on our
                behalf, such as credit bureaus, data analysis, payment
                processing, customer service, and marketing assistance.
              </li>
              <li>
                We may share your information in response to a legal request
                (like a court order) if we believe in good faith that the law
                requires us to do so.
              </li>
            </ul>

            <h4>4. Your Choices About Your Information</h4>
            <ul>
              <li>
                You may update your account at any time by logging in and
                changing your profile settings.
              </li>
              <li>
                You may unsubscribe from email communications from us by
                clicking on the “unsubscribe link” provided in such
                communications.
              </li>
            </ul>

            <h4>5. How We Protect Your Information</h4>
            <p>
              We take reasonable measures to protect your information from
              unauthorized access or against loss, misuse, or alteration by
              third parties.
            </p>

            <h4>6. Children’s Privacy</h4>
            <p>
              Our Service does not address anyone under the age of 13. We do not
              knowingly collect personally identifiable information from
              children under 13.
            </p>

            <h4>7. Changes to Our Privacy Policy</h4>
            <p>
              We may modify or update this Privacy Policy from time to time, so
              please review it periodically. We may provide you with notice of
              material changes to the policy, as appropriate, and update the
              ‘Effective Date’ at the top of this page.
            </p>

            <h4>8. Contact Us</h4>
            <p>
              If you have any questions about this Privacy Policy, please
              contact us at [Insert Contact Information].
            </p>
            <p>By using our Service, you consent to this Privacy Policy.</p>
            <p>
              This privacy policy template is provided as a general guideline.
              Please consult with a legal professional to ensure your privacy
              policy complies with all applicable laws and regulations,
              especially given the specific nature of financial information and
              credit reporting.
            </p>
          </div>
        </div>
      </section>
      {/* ================ Banner Section End ================ */}

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
