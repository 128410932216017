import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Success from "../success";
import { addToken } from "../../../store/reducers/userToken";

const Authentications = () => {
  const { userId } = useParams();
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();

  const user = useSelector((store) => store?.auth?.data);

  useEffect(() => {
    window.addEventListener("array-event", handleArrayEvent);

    return () => {
      window.removeEventListener("array-event", handleArrayEvent);
    };
  }, [userId]);

  const handleArrayEvent = (arrayEvent) => {
    const { tagName, event, metadata = {} } = arrayEvent.detail;

    if (event === "success" && Object.keys(metadata).length > 0) {
      setSuccess(true);
      dispatch(
        addToken(
          {
            userId: userId,
            userToken: metadata?.["user-token"],
          },
          false
        )
      );
    }
  };
  return (
    <div>
      {!success && (
        <array-authentication-kba
          appKey={process.env.REACT_APP_APP_KEY}
          userId={userId}
          showResultPages="true"
          sandbox="true"
        ></array-authentication-kba>
      )}
      {success && <Success />}
    </div>
  );
};
export default Authentications;
