import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./dashboard.module.scss";
import Header from "../../shared/header/header";
import Profile from "../profile";
import { me } from "../../../store/reducers/user";
import PersonalCredit from "../personalCredit";
import { Link } from "react-router-dom";
import ConfirmationModal from "../../components/modals/confirmation.modal";
import constants from "../../../utils/constants";
import toastMessage from "../../../utils/toastMessage";
import CreditSimulatorDashboard from "../credit-simulator";
import CreditGenius from "../credit-genius";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("Dashboard");
  const [showModal, setShowModal] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const userDetails = useSelector((state) => state.auth?.data?.user);
  const [isEmailVerified, setIsEmailVerified] = useState(
    userDetails?.isEmailVerified
  );
  useEffect(() => {
    dispatch(me()).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constants.THUNK_STATUS.FULFILLED:
          setIsEmailVerified(response?.payload?.user?.isEmailVerified);
          return;
        case constants.THUNK_STATUS.REJECTED:
          toastMessage("error", response?.payload?.message);
          return;
        default:
          return;
      }
    });
  }, []);

  const handleTabChange = (tabName) => {
    if (
      (tabName === "Personal Credit" || tabName === "Create Simulator+") &&
      !isEmailVerified
    ) {
      setShowModal(true);
      setShowValidation(true);
      return;
    }
    setActiveTab(tabName);
  };

  return (
    <>
      <Header />
      <div className={styles.inner_page_wrapper}>
        <div className="container">
          <div
            className={
              activeTab === "Credit Genius"
                ? `${styles.chat_page_wrapper} ${styles.page_wrapper_row}`
                : styles.page_wrapper_row
            }
          >
            <div className={styles.page_col_left}>
              <div className={styles.sidebar_col_menu}>
                <ul>
                  <li onClick={() => handleTabChange("Dashboard")}>
                    <Link
                      className={activeTab === "Dashboard" ? styles.active : ""}
                    >
                      <img
                        src={"./images/personal_credit.svg"}
                        alt="personal_credit"
                      />
                      Dashboard
                    </Link>
                  </li>
                  <li onClick={() => handleTabChange("Personal Credit")}>
                    <Link
                      className={
                        activeTab === "Personal Credit" ? styles.active : ""
                      }
                    >
                      <img
                        src={"./images/personal_credit.svg"}
                        alt="personal_credit"
                      />
                      Personal Credit
                    </Link>
                  </li>
                  <li onClick={() => handleTabChange("Credit Genius")}>
                    <Link
                      className={
                        activeTab === "Credit Genius" ? styles.active : ""
                      }
                    >
                      <img
                        src={"./images/credit_genius.svg"}
                        alt="credit_genius"
                      />
                      Credit Genius
                    </Link>
                  </li>
                  <li onClick={() => handleTabChange("Create Simulator+")}>
                    <Link
                      className={
                        activeTab === "Create Simulator+" ? styles.active : ""
                      }
                    >
                      <img
                        src={"./images/credit_simulator.svg"}
                        alt="credit_simulator"
                      />
                      Create Simulator+
                    </Link>
                  </li>
                  <li onClick={() => handleTabChange("Credit Games")}>
                    <Link
                      className={
                        activeTab === "Credit Games" ? styles.active : ""
                      }
                    >
                      <img
                        src={"./images/credit_games.svg"}
                        alt="credit_games"
                      />
                      Credit Games
                    </Link>
                  </li>
                  <li onClick={() => handleTabChange("Credit Card Offer")}>
                    <Link
                      className={
                        activeTab === "Credit Card Offer" ? styles.active : ""
                      }
                    >
                      <img
                        src={"./images/credit_card_offer.svg"}
                        alt="credit_card_offer"
                      />
                      Credit Card Offer
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.page_col_right}>
              {activeTab === "Dashboard" && (
                <Profile
                  showValidation={showValidation}
                  setShowValidation={setShowValidation}
                  setIsEmailVerified={setIsEmailVerified}
                  setActiveTab={setActiveTab}
                />
              )}
              {activeTab === "Personal Credit" && (
                <PersonalCredit userId={userDetails?.userId} />
              )}
              {activeTab === "Credit Genius" && (
                <CreditGenius
                  userId={userDetails?.userId}
                  isEmailVerified={isEmailVerified}
                  isSubscribed={userDetails?.isSubscribed}
                  setActiveTab={setActiveTab}
                />
              )}
              {activeTab === "Create Simulator+" && (
                <CreditSimulatorDashboard userId={userDetails?.userId} />
              )}
              {activeTab === "Credit Games" && (
                <div>Content for Credit Games</div>
              )}
              {activeTab === "Credit Card Offer" && (
                <div>Content for Credit Card Offer</div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <ConfirmationModal
          showModal={showModal}
          setShowModal={setShowModal}
          title="We just need to collect some information"
          message="Your information will never be used without your permission."
          onConfirm={() => {
            setShowModal(false);
          }}
        />
      )}
    </>
  );
};

export default Dashboard;
