import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export const PublicRoute = ({ children }) => {
  const location = useLocation();
  const { user, userToken } = useSelector((state) => state.auth?.data);

  // If user is logged in, redirect to the dashboard
  if (userToken?.accessToken && user?.userType === "User") {
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  return children || <Outlet />;
};

export const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const { user, userToken } = useSelector((state) => state.auth?.data);

  // If user is not logged in, redirect to the login page
  if (!userToken?.accessToken || user?.userType !== "User") {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children || <Outlet />;
};
