export const getFirstAndLastName = (displayName) => {
  const [firstName, ...rest] = displayName.split(" ");
  const lastName = rest.join(" ");

  return {
    firstName,
    lastName,
  };
};

export const titleCase = (str) => {
  return str?.toLowerCase().replace(/\b\w/g, (char) => char?.toUpperCase());
};